import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FluidImage from "../../components/FluidImage"
import contentParser from "gatsby-wpgraphql-inline-images"

const Post = ({ pageContext }) => {
  const {
    post: { title, content, featuredImage },
  } = pageContext

  const pluginOptions = {
    wordPressUrl: `https://admin.equityalliancemn.org/`,
    uploadsUrl: `https://admin.equityalliancemn.org/wp-content/uploads/`,
  }

  return (
    <Layout>
      <SEO title={title} />
      <FluidImage image={featuredImage} style={{ marginBottom: "15px" }} />
      <div className="container single-post-div">
        <div className="main-section main-blog-section">
          <div className="small-column">
            <h2 className="blog-title"> {title} </h2>
            {/*<hr />*/}
            {/*<div dangerouslySetInnerHTML={{ __html: content }} />*/}
            {contentParser({ content }, pluginOptions)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post
